import React from 'react'
import { css } from '@emotion/core'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import heroBackground from '../images/webinar-hero-image.jpg'
import TinyAnimate from 'TinyAnimate'
import color from 'color'
// Components
import Hero from '../components/hero'
import common from '../styles/common'
import colors from '../styles/colors'
import typography from '../styles/typography'

function scrollToSignup() {
  console.log('scrollToSignup')
  let from = window.pageYOffset
  let to =
    document.getElementById('mc_embed_signup').offsetTop -
    common.headerHeight -
    100
  TinyAnimate.animate(
    from,
    to,
    240,
    scrollY => void window.scrollTo(0, scrollY),
    'easeInOutQuart'
  )
}

const KontaktPage = () => (
  <Layout>
    <Helmet>
      <title>Ekko App - Hvordan du finder dit optimale kanalmiks</title>
      <meta
        property="og:title"
        content="Hvordan du finder dit optimale kanalmiks"
      />
      <meta
        property="og:description"
        content={
          'I September 2020 afholdt vi webinar med Senior konsulent Pia Baagø Skat-Rørdam fra Trustformation. Hun er ekspert i at nå og engagere medarbejdere. Det har hun gjort i over 20 år, bl.a. som tidligere HR direktør for en stor global logistikvirksomhed.'
        }
      />
      <meta property="og:image" content={heroBackground} />
    </Helmet>
    <Hero
      title="Hvordan du finder dit optimale kanalmiks"
      subTitle="En gennemgang af de 7 mest udbredte kanaler til intern kommunikation"
      primaryAction="Download herunder"
      primaryActionOnClick={scrollToSignup}
      customBackgroundStyles={{
        filter: `brightness(60%)`,
        backgroundImage: `url(${heroBackground})`,
        backgroundSize: 'cover',
      }}
    />
    <div css={container} className={'container'}>
      <p className="lead">
        I September 2020 afholdt vi webinar med Senior konsulent{' '}
        <a
          href="https://www.linkedin.com/in/piaskatrordam/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Pia Baagø Skat-Rørdam
        </a>{' '}
        fra Trustformation. Hun er ekspert i at nå og engagere medarbejdere. Det
        har hun gjort i over 20 år, bl.a. som tidligere HR direktør for en stor
        global logistikvirksomhed.
      </p>
      <p>
        Sammen har vi kigget på, hvad du som HR- eller kommunikationsmedarbejder
        skal gøre, for at finde det optimale kanalmiks til at nå dine
        medarbejdere effektivt.
      </p>

      <p>Download oplæg og video og bliv skarp på:</p>
      <ul>
        <li>Den afgørende forskel på information og kommunikation </li>
        <li>
          De 3 spørgsmål og 3 metoder, der hjælper dig godt på vej til det rette
          kanalmiks{' '}
        </li>
        <li>Hvilke fordele og ulemper 7 typiske interne kanaler har </li>
        <li>
          Hvordan 3 andre virksomheder helt konkret har fået mere ud af deres
          kommunikationskræfter med Ekko App{' '}
        </li>
        <li>Hvordan du kan få mere hjælp til din interne kommunikation </li>
      </ul>

      <div>
        {/* Begin Mailchimp Signup Form */}
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
          rel="stylesheet"
          type="text/css"
        />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n\t#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n',
          }}
        />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n\t#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px;}\n\t#mergeRow-gdpr {margin-top: 20px;}\n\t#mergeRow-gdpr fieldset label {font-weight: normal;}\n\t#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;}\n',
          }}
        />
        <div id="mc_embed_signup">
          <form
            action="https://ekkoapp.us4.list-manage.com/subscribe/post?u=fe5b97a5226e0eb348855de14&id=21e0b41f5d"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <h2>Sign dig op og få tilsendt PDF og video</h2>
            <div id="mc_embed_signup_scroll">
              <div className="indicates-required">
                <span className="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">Navn </label>
                <input type="text" name="FNAME" className id="mce-FNAME" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Emailadresse <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                />
              </div>
              <div
                id="mergeRow-gdpr"
                className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
              >
                <div className="content__gdpr">
                  <label style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                    Hold dig orienteret om intern kommunikation{' '}
                  </label>
                  <p>
                    Vi laver indhold om effektiv intern kommunikation. Vil du
                    modtage mere af ovenstående indhold fra Ekko App der kan
                    hjælpe dig til at blive den skarpeste interne kommunikatør?
                  </p>
                  <fieldset
                    className="mc_fieldset gdprRequired mc-field-group"
                    name="interestgroup_field"
                  >
                    <label className="checkbox subfield" htmlFor="gdpr_46658">
                      <input
                        type="checkbox"
                        id="gdpr_46658"
                        name="gdpr[46658]"
                        defaultValue="Y"
                        className="av-checkbox gdpr"
                      />
                      <span>Ja, tak </span>{' '}
                    </label>
                    <label className="checkbox subfield" htmlFor="gdpr_46666">
                      <input
                        type="checkbox"
                        id="gdpr_46666"
                        name="gdpr[46666]"
                        defaultValue="Y"
                        className="av-checkbox gdpr"
                      />
                      <span>Nej, tak</span>{' '}
                    </label>
                  </fieldset>
                  <p>
                    Hvis du ikke vil modtage mails fra os kan du altid afmelde
                    dig nemt via linket i emailen vi sender til dig efter
                    tilmelding.
                  </p>
                </div>
                <div className="content__gdprLegal">
                  <p>
                    We use Mailchimp as our marketing platform. By clicking
                    below to subscribe, you acknowledge that your information
                    will be transferred to Mailchimp for processing.{' '}
                    <a href="https://mailchimp.com/legal/" target="_blank">
                      Learn more about Mailchimp's privacy practices here.
                    </a>
                  </p>
                </div>
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: 'none' }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: 'none' }}
                />
              </div>{' '}
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_fe5b97a5226e0eb348855de14_21e0b41f5d"
                  tabIndex={-1}
                  defaultValue
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  defaultValue="Send mig PDF og video"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                />
              </div>
            </div>
          </form>
        </div>
        {/*End mc_embed_signup*/}
      </div>
    </div>
  </Layout>
)

function container() {
  return css`
    padding-top: 100px;
    padding-bottom: 150px;
    max-width: 700px !important;

    p.lead {
      max-width: 800px;
    }

    a {
      color: ${colors.seaBlue};
      text-decoration: none;
      border-bottom: 1px ${colors.seaBlue} dashed;
    }

    ul {
      margin-left: 1.1rem;

      li {
        color: ${colors.deepSeaBlue};
        margin-bottom: 0.5rem;
      }
    }

    h2 {
      margin: 0rem 0 1rem 0 !important;
      color: ${colors.deepSeaBlue};
    }

    form {
      margin: 3rem 0;
      border: 1px ${colors.midGrey} solid;
      border-radius: 4px;
      padding: 1rem !important;

      .content__gdprLegal {
        p {
          font-size: 0.8rem;
        }
      }

      label {
        color: ${colors.deepSeaBlue};
      }

      fieldset {
        border: none;

        label {
          display: flex;
          flex-direction: row;
          align-items: end;
          justify-content: flex-start;
        }

        input {
          width: auto;
          background-color: transparent;
        }
      }
      .hidden {
        display: none;
      }
      .hidden-input {
        position: absolute;
        left: -5000px;
        aria-hidden: true;
      }

      /*
        Never ever do something like the following anywhere!
      */
      .button {
        ${typography.button} display: inline-block !important;
        line-height: 45px !important;
        padding: 0rem 4.5rem !important;
        text-align: center !important;
        border-radius: 30px !important;
        outline: 0px !important;
        cursor: pointer !important;
        box-shadow: ${common.boxShadow} !important;
        border: 0px !important;
        transition: all 200ms ease !important;
        text-decoration: none !important;
        position: relative !important;
        height: initial !important;
        background-color: ${colors.seaGreen} !important;
        border: 1px ${colors.seaGreen} solid !important;
        color: ${colors.white} !important;

        &:hover,
        &:focus {
          background-color: ${color(colors.seaGreen)
            .lighten(0.2)
            .hex()};
          border: 1px ${colors.seaGreen} solid !important;
        }
        &:active {
          background-color: ${color(colors.seaGreen)
            .darken(0.1)
            .hex()} !important;
          border: 1px
            ${color(colors.seaGreen)
              .darken(0.1)
              .hex()}
            solid !important;
        }
      }
      /*
        End of: Never ever do something like the following anywhere!
      */

      .footnote {
        font-size: 0.8rem;
      }
    }

    #mce-responses {
      ${typography.regular} text-align: center;
      font-size: 1.5rem;
      margin: 60px 0 70px 0;

      #mce-success-response {
        color: ${colors.seaGreen};
        text-decoration-line: underline;
      }

      #mce-error-response {
        color: ${colors.red};
      }
    }

    #mc_embed_signup div.mce_inline_error {
      background-color: ${colors.red};
    }

    #mc_embed_signup input.mce_inline_error {
      border-color: ${colors.red};
    }
  `
}

export default KontaktPage
